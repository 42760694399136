import { typography } from "@components/solid/classLists";
import Icon from "@components/solid/Icon";
import type { ParentComponent, Setter } from "solid-js";
import styles from "./PlugPlaceholder.module.css";

export const PlugLoadingPlaceholder: ParentComponent = (props) => (
  <div class={styles["plug-loading-placeholder"]}>{props.children}</div>
);

export const PlugErrorPlaceholder: ParentComponent<{
  refSetter?: Setter<HTMLButtonElement | undefined>;
}> = (props) => (
  <button ref={props.refSetter} class={styles["plug-error-placeholder"]}>
    <Icon iconID="nrkMedia404Notfound" />
    <span classList={typography({ name: "SubheadBold" })}>
      Noe gikk galt...
    </span>
    <span classList={typography({ name: "Footnote" })}>
      Klikk for å prøve igjen
    </span>
  </button>
);
