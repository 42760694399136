import type { components } from "@src-types/pages-schema";
import {
  createContext,
  useContext,
  type ParentComponent,
  type Resource,
} from "solid-js";

type PageOverviewResponse = components["schemas"]["Page"];

export const PageOverviewContext =
  createContext<Resource<PageOverviewResponse>>();

export const PageOverviewProvider: ParentComponent<{
  page: Resource<PageOverviewResponse>;
}> = (props) => {
  return (
    <PageOverviewContext.Provider value={props.page}>
      {props.children}
    </PageOverviewContext.Provider>
  );
};

export const usePageContext = () => {
  const context = useContext(PageOverviewContext);
  if (!context) {
    throw new Error("usePageContext: cannot find a PageOverviewContext");
  }
  return context;
};
