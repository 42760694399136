import { plugOutline } from "@components/solid/classLists";
import PlugResolver from "@components/solid/front-page/PlugResolver";
import { PlugLoadingPlaceholder } from "@components/solid/PlugPlaceholders";
import SnapSlider from "@components/solid/SnapSlider";
import { createVisibilityObserver } from "@solid-primitives/intersection-observer";
import type {
  PlugRef,
  PlugSize,
  SupportedDisplayContract,
} from "@src-types/plug";
import { Index, Match, Switch, createMemo, type Component } from "solid-js";

type Props = {
  plugRefs: PlugRef[];
  displayContract: SupportedDisplayContract;
  size: PlugSize;
};

const PlugSlider: Component<Props> = (props) => {
  const useVisibilityObserver = createVisibilityObserver({});
  return (
    <SnapSlider listGutter="both">
      <Index each={props.plugRefs}>
        {(item, _) => {
          let itemRef: HTMLElement | undefined;
          const visible = useVisibilityObserver(() => itemRef);
          const observed = createMemo((prev) => visible() || prev, false);
          return (
            <li
              tabIndex={0}
              ref={(el) => (itemRef = el)}
              classList={plugOutline({
                contract: props.displayContract,
                size: props.size,
              })}
              data-contract={props.displayContract}
              data-size={props.size}
              style={{ "scroll-snap-align": "end" }} // TODO: Make configurable?
            >
              <Switch>
                <Match when={!observed()}>
                  <li
                    classList={plugOutline({
                      contract: props.displayContract,
                      size: props.size,
                    })}
                  >
                    <PlugLoadingPlaceholder />
                  </li>
                </Match>
                <Match when={observed()}>
                  <PlugResolver
                    plugRef={item()}
                    type={props.displayContract}
                    size={props.size}
                  />
                </Match>
              </Switch>
            </li>
          );
        }}
      </Index>
    </SnapSlider>
  );
};

export default PlugSlider;
