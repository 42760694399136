import { typography } from "@components/solid/classLists";
import { Show, type ParentComponent } from "solid-js";
import styles from "./ShelfsSection.module.css";

type Props = {
  index: number;
  title?: string;
  type: "subgrid" | "content" | "breakout" | "popout";
  left?: "breakout";
  right?: "breakout";
  overflow?: "clip" | "visible";
};

const resolveAligmentStyles = ({
  type,
  left,
  right,
  overflow,
}: Pick<Props, "type" | "left" | "right" | "overflow">) => ({
  [`${styles[type]}`]: true,
  [`${styles[`breakout--left`]}`]: left === "breakout",
  [`${styles[`breakout--right`]}`]: right === "breakout",
  [`${styles[`overflow--${overflow}`]}`]: overflow === "clip",
});

const ShelfsSection: ParentComponent<Props> = (props) => {
  return (
    <section
      style={{ order: props.index }}
      classList={resolveAligmentStyles(props)}
    >
      <Show when={props.title}>
        <h2
          class={styles["heading"]}
          classList={typography({ name: "HeadlineExtraBold" })}
        >
          {props.title}
        </h2>
      </Show>
      {props.children}
    </section>
  );
};

export default ShelfsSection;
