import { fetchPlugData } from "@components/solid/front-page/common";
import { MultiHeroContent } from "@components/solid/front-page/MultiHero/MultiHeroContent";
import { MultiHeroImage } from "@components/solid/front-page/MultiHero/MultiHeroImage";
import { MultiHeroSlider } from "@components/solid/front-page/MultiHero/MultiHeroSlider";
import type { components } from "@src-types/pages-schema";
import type { PlugRef } from "@src-types/plug";
import {
  createResource,
  createSignal,
  Match,
  onMount,
  Show,
  Switch,
  type Component,
} from "solid-js";
import styles from "./Index.module.css";

type Props = {
  section: components["schemas"]["IncludedSection"];
};

const MulitHero: Component<Props> = (props) => {
  const [activePlug, setActivePlug] = createSignal<PlugRef>();
  const onPlugMouseOver = (ref: PlugRef) => setActivePlug(ref);
  onMount(() => setActivePlug(props.section.included.plugRefs[0].ref));

  const [plug] = createResource(activePlug, fetchPlugData);

  return (
    <>
      <div class={styles["banner"]}>
        <Show
          when={!plug.loading}
          fallback={<div class={styles["loading-placeholder"]} />}
        >
          <Switch>
            <Match when={plug()}>
              {(plug) => (
                <>
                  <div class={styles["img-wrapper"]}>
                    <MultiHeroImage plug={plug()} />
                  </div>
                  <div class={styles["content-wrapper"]}>
                    <MultiHeroContent plug={plug()} />
                  </div>
                </>
              )}
            </Match>
          </Switch>
        </Show>
      </div>
      <div class={styles["slider-wrapper"]}>
        <MultiHeroSlider
          plugRefs={props.section.included.plugRefs.map(({ ref }) => ref)}
          onPlugMouseOver={onPlugMouseOver}
        />
      </div>
    </>
  );
};

export default MulitHero;
