import { plugOutline } from "@components/solid/classLists";
import { setActivePlug } from "@components/solid/front-page/MultiHero/MultiHeroShared";
import PlugResolver from "@components/solid/front-page/PlugResolver";
import SnapSlider from "@components/solid/SnapSlider";
import { Index, onMount, type Component } from "solid-js";
import type { PlugRef } from "src/types/plug";

export const MultiHeroSlider: Component<{
  plugRefs: PlugRef[];
  onPlugMouseOver: (ref: PlugRef) => void;
}> = (props) => {
  onMount(() => setActivePlug(props.plugRefs[0]));
  return (
    <SnapSlider listGutter="both">
      <Index each={props.plugRefs}>
        {(item, _) => (
          <li
            tabIndex={0}
            onMouseOver={() => props.onPlugMouseOver(item())}
            classList={plugOutline({ contract: "squared", size: "medium" })}
          >
            <PlugResolver
              plugRef={item()}
              type="squaredMultiHero"
              size="medium"
            />
          </li>
        )}
      </Index>
    </SnapSlider>
  );
};
