import { button, typography } from "@components/solid/classLists";
import ProgressMediaPlay from "@components/solid/common/ProgressMediaPlay";
import Icon from "@components/solid/Icon";
import type { Plug, PlugComponentVariants } from "@src-types/plug";
import { type Component } from "solid-js";
import { Dynamic } from "solid-js/web";
import styles from "./MultiHeroContent.module.css";

export const MultiHeroContent: Component<{ plug: Plug }> = (props) => {
  return (
    <>
      <div class={styles["text-wrp"]}>
        <span
          class={styles["title-main"]}
          classList={typography({ name: "Title1Heavy" })}
        >
          <Dynamic component={mainTitle[props.plug.type]} {...props.plug} />
        </span>
        <span
          class={styles["title-sub"]}
          classList={typography({ name: "Title2Bold" })}
        >
          {props.plug?.title}
        </span>
        <span classList={typography({ name: "Body" })}>
          {props.plug?.description}
        </span>
      </div>
      <div class={styles["action-bar"]}>
        <Dynamic
          component={playButtonWithLabel[props.plug.type] ?? "span"}
          {...props.plug}
        />
      </div>
    </>
  );
};

export type PlugVariants = {
  episode: (plug: Extract<Plug, { type: "episode" }>) => string;
  podcastEpisode: (plug: Extract<Plug, { type: "podcastEpisode" }>) => string;
  podcastSeason: (plug: Extract<Plug, { type: "podcastSeason" }>) => string;
  series: (plug: Extract<Plug, { type: "series" }>) => string;
  standaloneProgram: (
    plug: Extract<Plug, { type: "standaloneProgram" }>,
  ) => string;
  channel: (plug: Extract<Plug, { type: "channel" }>) => string;
  podcast: (plug: Extract<Plug, { type: "podcast" }>) => string;
  link: (plug: Extract<Plug, { type: "link" }>) => string;
  page: (plug: Extract<Plug, { type: "page" }>) => string;
};

const FALLBACK_TITLE = "Ingen Tittel";

const mainTitle: PlugVariants = {
  episode: (plug) => plug.episode.episodeTitle ?? FALLBACK_TITLE,
  channel: (plug) => plug.channel.channelTitle ?? FALLBACK_TITLE,
  link: () => "Link",
  page: () => "Page",
  podcast: (plug) => plug.podcast.podcastTitle ?? FALLBACK_TITLE,
  podcastEpisode: (plug) => plug.podcastEpisode.podcastTitle ?? FALLBACK_TITLE,
  podcastSeason: (plug) =>
    plug.podcastSeason.podcastSeasonTitle ?? FALLBACK_TITLE,
  series: (plug) => plug.series.seriesTitle ?? FALLBACK_TITLE,
  standaloneProgram: (plug) =>
    plug.standaloneProgram.programTitle ?? FALLBACK_TITLE,
};

const playButtonWithLabel: PlugComponentVariants = {
  episode: () => (
    <>
      <button>
        <ProgressMediaPlay progress={0} size="large" />
      </button>
      <span classList={typography({ name: "SubheadRegular" })}>
        Hør episode
      </span>
    </>
  ),
  podcastEpisode: () => (
    <>
      <button>
        <ProgressMediaPlay progress={0} size="large" />
      </button>
      <span classList={typography({ name: "SubheadRegular" })}>
        Hør episode
      </span>
    </>
  ),
  channel: () => (
    <>
      <button>
        <ProgressMediaPlay progress={0} size="large" variant="live" />
      </button>
      <span classList={typography({ name: "SubheadRegular" })}>
        Hør direkte
      </span>
    </>
  ),
  series: () => (
    <>
      <button>
        <ProgressMediaPlay progress={0} size="large" />
      </button>
      <span classList={typography({ name: "SubheadRegular" })}>Hør serien</span>
    </>
  ),
  podcastSeason: () => (
    <>
      <button>
        <ProgressMediaPlay progress={0} size="large" />
      </button>
      <span classList={typography({ name: "SubheadRegular" })}>
        Hør sesongen
      </span>
    </>
  ),
  standaloneProgram: () => (
    <>
      <button>
        <ProgressMediaPlay progress={0} size="large" />
      </button>
      <span classList={typography({ name: "SubheadRegular" })}>
        Hør programmet
      </span>
    </>
  ),
  podcast: () => (
    <>
      <button>
        <ProgressMediaPlay progress={0} size="large" />
      </button>
      <span classList={typography({ name: "SubheadRegular" })}>
        Hør podkasten
      </span>
    </>
  ),
  link: () => (
    <>
      <button
        classList={button({
          type: "secondary",
          variant: "icon",
          size: "l",
          shape: "round",
        })}
      >
        <Icon iconID="nrkOpenInNew" />
      </button>
      <span>Åpne lenke</span>
    </>
  ),
  page: () => (
    <>
      <button
        classList={button({
          type: "secondary",
          variant: "icon",
          size: "l",
          shape: "round",
        })}
      >
        <Icon iconID="nrkCategory" />
      </button>
      <span classList={typography({ name: "SubheadRegular" })}>
        Åpne kategori
      </span>
    </>
  ),
};
