import { fetchPlugData } from "@components/solid/front-page/common";
import { plugVariants } from "@components/solid/front-page/Plugs";
import {
  PlugErrorPlaceholder,
  PlugLoadingPlaceholder,
} from "@components/solid/PlugPlaceholders";
import type { PlugSize, SupportedDisplayContract } from "@src-types/plug";
import {
  createEffect,
  createResource,
  createSignal,
  Match,
  Show,
  Suspense,
  Switch,
  type Component,
} from "solid-js";
import { Dynamic } from "solid-js/web";

export const PlugResolver: Component<{
  plugRef: string;
  type: SupportedDisplayContract;
  size: PlugSize;
}> = (props) => {
  const [errorRef, setErrorRef] = createSignal<HTMLButtonElement>();
  const [plug, { refetch }] = createResource(
    props.plugRef,
    fetchPlugData,
  );
  // TODO: Cleanup event-listeners?
  createEffect(() => {
    errorRef()?.addEventListener("click", () => refetch());
  });

  return (
    <Suspense>
      <Show when={!plug.loading} fallback={<PlugLoadingPlaceholder />}>
        <Switch>
          <Match when={plug.error}>
            <PlugErrorPlaceholder refSetter={setErrorRef} />
          </Match>
          <Match when={plug()}>
            {(plug) => (
              <Dynamic
                component={plugVariants[props.type]}
                plug={plug()}
                displayContract={props.type}
                size={props.size}
              />
            )}
          </Match>
        </Switch>
      </Show>
    </Suspense>
  );
};

export default PlugResolver;
