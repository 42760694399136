import { psClient } from "@api/request";
import type { LoginSession } from "@nrk/innlogging-web/redirect-login-client";
import {
  For,
  Match,
  Show,
  Switch,
  createResource,
  createSignal,
  splitProps,
  type Component,
  type ParentComponent,
} from "solid-js";

import { loginSession } from "@components/solid/AuthContext";
import { typography } from "@components/solid/classLists";
import ProgressMediaPlay from "@components/solid/common/ProgressMediaPlay";
import styles from "@components/solid/front-page/UserResumableListenings.module.css";
import { PictureSources } from "@components/solid/Image";
import SnapSlider from "@components/solid/SnapSlider";
import type { components } from "@src-types/personlig-schema";
import type { JSX } from "solid-js/jsx-runtime";
import { Dynamic } from "solid-js/web";

/**
 * https://psapi-preprod.nrk.no/documentation/redoc/personlig/#tag/Continuations/operation/ListContinuations
 */

namespace ContinuationsPlug {
  export type Episode = components["schemas"]["ContinuationEpisode"];
  export type SingleProgram =
    components["schemas"]["ContinuationSingleProgram"];
  export type Any = Episode | SingleProgram;
}

export type Response = components["schemas"]["Continuations"];

const fetchContinuationsData = async (session: LoginSession) => {
  return await psClient.GET<Response>(
    `/radio/userdata/${session.user?.sub}/continuations`,
    {
      config: {
        headers: {
          Authorization: `Bearer ${session.accessToken}`,
        },
      },
    },
  );
};

const UserResumableListenings: Component<{}> = () => {
  const [listRef, setListRef] = createSignal<HTMLElement>();
  const [resource] = createResource(loginSession, fetchContinuationsData);
  return (
    <Show when={!resource.loading}>
      <SnapSlider customList={listRef()} listGutter="both">
        <div
          class={styles["list-scroll-wrapper"]}
          data-gutter="both"
          ref={(el: HTMLElement) => setListRef(el)}
        >
          <ul class={styles["list"]}>
            <Switch>
              <Match when={resource()}>
                <For each={resource()?.continuations}>
                  {(resumeable, _) => (
                    <Switch>
                      <Match
                        when={resumeable.type === "singleProgram" && resumeable}
                      >
                        {(singleProgram) => (
                          <ResumbleSingleProgramPlug {...singleProgram()} />
                        )}
                      </Match>
                      <Match when={resumeable.type === "episode" && resumeable}>
                        {(episode) => (
                          <>
                            <ResumbleEpisodePlug {...episode()} />
                          </>
                        )}
                      </Match>
                    </Switch>
                  )}
                </For>
              </Match>
            </Switch>
          </ul>
        </div>
      </SnapSlider>
    </Show>
  );
};

const ResumbleEpisodePlug: Component<ContinuationsPlug.Episode> = (props) => {
  const [
    {
      _embedded: { catalogData, progress },
    },
    _,
  ] = splitProps(props, ["_embedded", "_links"]);
  return (
    <ResumablePlug {...props}>
      <h3
        class={styles["overflow-ellipsis"]}
        classList={typography({ name: "SubheadBold" })}
      >
        {catalogData.title.at(0)?.title}
      </h3>
      <span
        class={`${styles["overflow-ellipsis"]} ${styles["meta-info"]}`}
        classList={typography({ name: "Footnote" })}
      >
        <Dynamic component={progressTypeLabel[progress.progress]} />
        <span>{catalogData.subtitle}</span>
      </span>
    </ResumablePlug>
  );
};

const ResumbleSingleProgramPlug: Component<ContinuationsPlug.SingleProgram> = (
  props,
) => {
  const [
    {
      _embedded: { catalogData, progress },
    },
    _,
  ] = splitProps(props, ["_embedded", "_links"]);
  return (
    <ResumablePlug {...props}>
      <h3
        class={styles["overflow-ellipsis"]}
        classList={typography({ name: "SubheadBold" })}
      >
        {catalogData.title.at(0)?.title}
      </h3>
      <span
        class={`${styles["overflow-ellipsis"]} ${styles["meta-info"]}`}
        classList={typography({ name: "Footnote" })}
      >
        <Dynamic component={progressTypeLabel[progress.progress]} />
      </span>
    </ResumablePlug>
  );
};

const ResumablePlug: ParentComponent<ContinuationsPlug.Any> = (props) => {
  const [
    {
      _embedded: { catalogData, progress },
    },
    _,
  ] = splitProps(props, ["_embedded", "_links"]);
  return (
    <li class={styles["card-item"]}>
      <picture class={styles["picture"]}>
        <PictureSources
          images={catalogData.image.map(({ url, width }) => ({
            uri: url,
            width,
          }))}
        />
        <img src={catalogData.image.at(-1)?.url} />
      </picture>
      <div class={styles["variant-wrapper"]}>{props.children}</div>
      <div class={styles["button-wrapper"]}>
        <button>
          <ProgressMediaPlay
            progress={progress.inProgress?.percentage ?? 0}
            size="large"
          />
        </button>
      </div>
    </li>
  );
};

type ProgressTypeVariants = Record<
  | components["schemas"]["ProgressType"]
  | components["schemas"]["SingleProgramProgressType"],
  () => JSX.Element
>;

const progressTypeLabel: ProgressTypeVariants = {
  inProgress: (): JSX.Element => (
    <>
      <span>Fortsett</span>
      <span> • </span>
    </>
  ),
  finished: (): JSX.Element => (
    <>
      <span>Fullført</span>
      <span> • </span>
    </>
  ),
  notStarted: (): JSX.Element => (
    <>
      <span>Ny</span>
      <span> • </span>
    </>
  ),
};

export default UserResumableListenings;
