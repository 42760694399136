import { createStore } from "solid-js/store";
import type { Plug, PlugRef } from "src/types/plug";

interface PlugsStore {
  plugs: { ref: PlugRef; data?: Plug }[];
}

export const [plugStore, setPlugStore] = createStore<PlugsStore>({ plugs: [] });

export const getStoredPlug = (ref: PlugRef): Plug | undefined =>
  plugStore.plugs.find((p) => p.ref === ref)?.data ?? undefined;

export const storePlug = (ref: PlugRef, data: Plug) =>
  setPlugStore("plugs", (plugs) => [...plugs, { ref, data }]);
