import { pagesClient } from "@api/request";
import { loginSession } from "@components/solid/AuthContext";
import type { Plug, PlugRef } from "@src-types/plug";
import { getStoredPlug, storePlug } from "@stores/plugStore";

export const fetchPlugData = async (ref: PlugRef): Promise<Plug> => {
  return (
    getStoredPlug(ref) ||
    pagesClient
      .GET<Plug>(`/v4/plugs/${ref}`, {
        config: {
          headers: {
            Authorization: `Bearer ${loginSession?.()?.accessToken}`,
          },
        },
      })
      .then((data) => {
        storePlug(ref, data);
        return data;
      })
  );
};
