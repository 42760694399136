import { button, typography } from "@components/solid/classLists";
import MoreDialogResolver from "@components/solid/common/MoreDialogResolver";
import ProgressMediaPlay from "@components/solid/common/ProgressMediaPlay";
import type { PlugProps } from "@components/solid/front-page/Plugs";
import Icon from "@components/solid/Icon";
import type { Plug } from "@src-types/plug";
import { formatDurationToHoursAndMinutes } from "@utils/date";
import { Match, splitProps, Switch, type Component } from "solid-js";
import { Dynamic } from "solid-js/web";
import styles from "./Hero.module.css";

const Hero: Component<PlugProps> = (props) => {
  let moreDialogRef: HTMLDialogElement;

  const [{ id, type, image, accessibilityLabel, title }, _] = splitProps(
    props.plug,
    ["id", "type", "image", "accessibilityLabel", "title"],
  );

  return (
    <div class={styles["wrp"]} data-type={type} data-id={id}>
      <div class={styles["img-ctr"]}>
        {/* TODO: Add live symbol with text for channel variant */}
        <picture>
          <source
            type="image/avif"
            sizes="(max-width: 1165px) 100vw, 65vw"
            srcset={image.webImages
              .map(({ uri, width }) => `${uri} ${width}w`)
              .join(",")}
          />
          <img
            class={styles["img"]}
            src={image.webImages.at(-1)?.uri}
            alt={accessibilityLabel}
          />
        </picture>
      </div>
      <div class={styles["ctn"]}>
        <div class={styles["txt-ctn"]}>
          <Dynamic component={promotion[props.plug.type]} {...props.plug} />
        </div>
        <div class={styles["action-ctr"]}>
          <Dynamic component={actionBar[props.plug.type]} {...props.plug} />
          <button
            onClick={() => moreDialogRef.showModal()}
            class={styles["more-button"]}
            classList={button({
              type: "primary",
              variant: "icon",
              shape: "round",
              size: "s",
            })}
          >
            <Icon iconID="nrkMore" />
          </button>
        </div>
      </div>
      <dialog class="nrk-modal" ref={(el) => (moreDialogRef = el)}>
        <MoreDialogResolver
          plug={props.plug}
          onClose={() => moreDialogRef.close()}
        />
      </dialog>
    </div>
  );
};

type PlugComponentVariants = {
  [K in Plug["type"]]: Component<Extract<Plug, { type: K }>>;
};

const actionBar: PlugComponentVariants = {
  episode: (props) => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
          <div class={styles["info-play"]}>
            <span classList={typography({ name: "SubheadBold" })}>
              Hør serien
            </span>
            <span classList={typography({ name: "Footnote" })}>
              {formatDurationToHoursAndMinutes(props.episode.duration)}
            </span>
          </div>
        </button>
      </>
    );
  },
  podcastSeason: (props) => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Hør serien
          </span>
          <span classList={typography({ name: "Footnote" })}>
            {props.podcastSeason.numberOfEpisodes} episoder
          </span>
        </div>
      </>
    );
  },
  series: () => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Hør serien
          </span>
        </div>
      </>
    );
  },
  standaloneProgram: () => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
        </button>
      </>
    );
  },
  channel: () => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" variant="live" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Hør direkte
          </span>
          <span></span>
        </div>
        <button
          classList={button({
            type: "primary",
            variant: "icon",
            shape: "round",
            size: "s",
          })}
        >
          {/* TODO: Create collapsing inline-icon to icon button */}
          <Icon iconID="nrkReload" />
        </button>
      </>
    );
  },
  podcast: (props) => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Hør podcasten
          </span>
          <span classList={typography({ name: "Footnote" })}>
            {props.podcast.numberOfEpisodes} episoder
          </span>
        </div>
      </>
    );
  },
  podcastEpisode: (props) => {
    return (
      <>
        <button>
          <ProgressMediaPlay progress={0} size="large" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Hør episoden
          </span>
          <span classList={typography({ name: "Footnote" })}>
            {formatDurationToHoursAndMinutes(props.podcastEpisode.duration)}
          </span>
        </div>
      </>
    );
  },
  link: () => {
    return (
      <>
        <button
          classList={button({ type: "secondary", variant: "icon", size: "l" })}
        >
          <Icon iconID="nrkOpenInNew" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Åpne lenke
          </span>
        </div>
      </>
    );
  },
  page: () => {
    return (
      <>
        <button
          classList={button({ type: "secondary", variant: "icon", size: "l" })}
        >
          <Icon iconID="nrkCategory" />
        </button>
        <div class={styles["info-play"]}>
          <span classList={typography({ name: "SubheadBold" })}>
            Gå til kategori
          </span>
        </div>
      </>
    );
  },
};

const promotion: PlugComponentVariants = {
  episode: (props) => <PromotionText {...props} />,
  podcastSeason: (props) => <PromotionText {...props} />,
  series: (props) => <PromotionText {...props} />,
  standaloneProgram: (props) => <PromotionText {...props} />,
  channel: (props) => <PromotionText {...props} />,
  podcast: (props) => <PromotionText {...props} />,
  podcastEpisode: (props) => <PromotionText {...props} />,
  link: (props) => <PromotionText {...props} />,
  page: (props) => <PromotionText {...props} />,
};

const PromotionText: Component<
  Pick<Plug, "title" | "description" | "tagline">
> = (props) => (
  <Switch
    fallback={
      <span
        class={styles["headline-regular"]}
        classList={typography({ name: "Title1Regular" })}
      >
        {props.title}
      </span>
    }
  >
    <Match when={props.tagline}>
      <h3
        class={styles["headline"]}
        classList={typography({ name: "Title1Bold" })}
      >
        {props.tagline}
      </h3>
      <span class={styles["txt"]} classList={typography({ name: "Preamble" })}>
        {props.title}
      </span>
    </Match>
  </Switch>
);

export default Hero;
