import { pagesClient } from "@api/request";
import { loginSession } from "@components/solid/AuthContext";
import { PageOverviewProvider } from "@components/solid/front-page/PageOverviewContext";
import { SectionResolver } from "@components/solid/front-page/SectionResolver";
import type { LoginSession } from "@nrk/innlogging-web/redirect-login-client";
import type { components } from "@src-types/pages-schema";
import {
  createResource,
  Index,
  Match,
  Show,
  Switch,
  type ParentComponent,
} from "solid-js";

type Props = {
  pageId: string;
};

type FetchPageOverviewDataProps = {
  session?: LoginSession;
  pageId: string;
};

type PageOverviewResponse = components["schemas"]["Page"];

async function fetchPageOverviewData({
  session,
  pageId,
}: FetchPageOverviewDataProps) {
  return await pagesClient.GET<PageOverviewResponse>(`/v4/pages/${pageId}`, {
    searchParams: {
      ...(session?.isLoggedIn && { userId: session?.user?.sub }),
    },
    config: {
      headers: {
        Authorization: `Bearer ${session?.accessToken}`,
      },
    },
  });
}

export const Shelfs: ParentComponent<Props> = (props) => {
  const [page] = createResource(
    { pageId: props.pageId, session: loginSession() },
    fetchPageOverviewData,
  );

  return (
    <PageOverviewProvider page={page}>
      <Show when={!page.loading}>
        <Switch>
          <Match when={page()}>
            {(page) => (
              <Index each={page().sections}>
                {(section, index) => (
                  <SectionResolver section={section()} index={index + 1} />
                )}
              </Index>
            )}
          </Match>
          <Match when={page.error}>
            <div>Error</div>
          </Match>
        </Switch>
      </Show>
    </PageOverviewProvider>
  );
};
