import { loginSession } from "@components/solid/AuthContext";
import MulitHero from "@components/solid/front-page/MultiHero/Index";
import { PageOverviewContext } from "@components/solid/front-page/PageOverviewContext";
import PlugResolver from "@components/solid/front-page/PlugResolver";
import PlugSlider from "@components/solid/front-page/PlugSlider";
import ShelfsSection from "@components/solid/front-page/ShelfsSection";
import UserResumableListenings from "@components/solid/front-page/UserResumableListenings";
import type { components } from "@src-types/pages-schema";
import type { SupportedDisplayContract } from "@src-types/plug";
import {
  lazy,
  Match,
  Show,
  splitProps,
  Suspense,
  Switch,
  useContext,
  type Component,
} from "solid-js";
import { Dynamic } from "solid-js/web";

type IncludedSectioneResponse = components["schemas"]["IncludedSection"];
type PlaceholderSectionResponse = components["schemas"]["PlaceholderSection"];
type SectionResponse = IncludedSectioneResponse | PlaceholderSectionResponse;

type SectionResolve = {
  [K in SectionResponse["type"]]: Component<
    Extract<SectionResponse, { type: K }> & { index: number }
  >;
};

const IncludedSection: SectionResolve["included"] = (props) => {
  const [{ included, index }] = splitProps(props, ["included", "index"]);
  return (
    <Switch>
      <Match when={included.displayContract === "radioMultiHero"}>
        <ShelfsSection type="subgrid" index={index}>
          <MulitHero section={props} />
        </ShelfsSection>
      </Match>
      <Match when={included.displayContract === "hero"}>
        <ShelfsSection type="popout" index={index}>
          <PlugResolver
            plugRef={included.plugRefs.map(({ ref }) => ref)[0]}
            type="hero"
            size="medium"
          ></PlugResolver>
        </ShelfsSection>
      </Match>
      <Match when={included.displayContract !== "radioMultiHero"}>
        <ShelfsSection title={included.title} type="breakout" index={index}>
          <PlugSlider
            plugRefs={included.plugRefs.map(({ ref }) => ref)}
            displayContract={
              included.displayContract as SupportedDisplayContract
            }
            size={included.plugSize}
          />
        </ShelfsSection>
      </Match>
    </Switch>
  );
};

const PlaceholderSection: SectionResolve["placeholder"] = (props) => {
  const [{ placeholder, index }] = splitProps(props, ["placeholder", "index"]);
  const page = useContext(PageOverviewContext);
  const recommendationId = () => page?.()?.recommendationId ?? "";
  const UserFavoritesShelf = lazy(
    () => import("@components/solid/front-page/UserFavoritesShelf"),
  );
  const PersonalisedRecommandations = lazy(
    () => import("@components/solid/front-page/PersonalisedRecommandations"),
  );
  const CrossDomainRecommendations = lazy(
    () => import("@components/solid/front-page/CrossDomainRecommendations"),
  );
  return (
    <Show when={loginSession()?.isLoggedIn}>
      <Switch>
        <Match when={placeholder.type === "myContent"}>
          <ShelfsSection
            type="breakout"
            title={placeholder.title}
            index={index}
          >
            <UserFavoritesShelf />
          </ShelfsSection>
        </Match>
        <Match when={placeholder.type === "continueListening"}>
          <ShelfsSection
            title={placeholder.title}
            type="breakout"
            overflow="clip"
            index={index}
          >
            <UserResumableListenings />
          </ShelfsSection>
        </Match>
        <Match when={placeholder.type === "personalisedRecommendations"}>
          <ShelfsSection
            title={placeholder.title}
            type="breakout"
            index={index}
          >
            <PersonalisedRecommandations
              pageRecommendationId={recommendationId()}
              position={index}
            />
          </ShelfsSection>
        </Match>
        <Match when={placeholder.type === "crossDomainRecommendations"}>
          <ShelfsSection
            title={placeholder.title}
            type="breakout"
            index={index}
          >
            <CrossDomainRecommendations
              pageId={recommendationId()}
              position={index}
            />
          </ShelfsSection>
        </Match>
      </Switch>
    </Show>
  );
};

const sectionMap: SectionResolve = {
  included: IncludedSection,
  placeholder: PlaceholderSection,
};

export const SectionResolver: Component<{
  section: SectionResponse;
  index: number;
}> = (props) => (
  <Suspense>
    <Dynamic
      index={props.index}
      component={sectionMap[props.section.type]}
      {...props.section}
    />
  </Suspense>
);
