import type { Plug } from "@src-types/plug";
import { type Component } from "solid-js";
import styles from "./MultiHeroImage.module.css";

export const MultiHeroImage: Component<{ plug?: Plug }> = (props) => (
  <>
    <div class={styles["image-shadow"]} />
    <picture>
      <source
        type="image/avif"
        sizes="100vw"
        srcset={props.plug?.backdropImage?.webImages
          .map(({ uri, width }) => `${uri} ${width}w`)
          .join(",")}
      />
      <img
        class={styles["img"]}
        loading="eager"
        fetchpriority="high"
        src={props.plug?.backdropImage?.webImages?.at(1)?.uri}
        alt={props.plug?.accessibilityLabel}
      />
    </picture>
  </>
);
