import { typography } from "@components/solid/classLists";
import Hero from "@components/solid/front-page/Hero/Hero";
import styles from "@components/solid/front-page/Plugs.module.css";
import type { Plug, PlugSize, SupportedDisplayContract } from "@src-types/plug";
import { type Component } from "solid-js";

export type PlugProps = {
  plug: Plug;
  displayContract: SupportedDisplayContract;
  size: PlugSize;
};

type ImagePlugProperties = Pick<Plug, "image" | "accessibilityLabel">;

type ImageProps = {
  sizes: string;
  loading: "lazy" | "eager";
  fetchpriority: "high" | "low";
};

const Image: Component<ImageProps & ImagePlugProperties> = (props) => (
  <picture>
    <source
      type="image/avif"
      sizes={props.sizes}
      srcset={props.image.webImages
        .map(({ uri, width }) => `${uri} ${width}w`)
        .join(",")}
    />
    <img
      loading="lazy"
      src={props.image.webImages.at(-1)?.uri}
      alt={props.accessibilityLabel}
    />
  </picture>
);

export const Title: Component<{ text?: string }> = (props) => (
  <span class={styles["title"]} classList={typography({ name: "SubheadBold" })}>
    {props.text}
  </span>
);

export const Description: Component<{ text?: string }> = (props) => (
  <span
    class={styles["description"]}
    classList={typography({ name: "Footnote" })}
  >
    {props.text}
  </span>
);

type PlugOptions = Record<SupportedDisplayContract, Component<PlugProps>>;

export const plugVariants: PlugOptions = {
  hero: Hero,
  simple: (props) => (
    <Image
      sizes="(max-width: 456px) 288px, clamp(288px, 10vw, calc(288px * 1.66))"
      loading="lazy"
      fetchpriority="low"
      image={props.plug.image}
      accessibilityLabel={props.plug.accessibilityLabel}
    />
  ),
  squared: (props) => (
    <>
      <Image
        sizes={
          props.size === "small"
            ? "(max-width: 456px) 136px, clamp(136px, 10vw, calc(136px * 1.66))"
            : props.size === "medium"
              ? "(max-width: 456px) 161px, clamp(161px, 10vw, calc(161px * 1.66))"
              : "(max-width: 456px) 250px, clamp(250px, 10vw, calc(250px * 1.66))"
        }
        loading="lazy"
        fetchpriority="low"
        image={props.plug.image}
        accessibilityLabel={props.plug.accessibilityLabel}
      />
      {/* <Title text={props.plug?.title} /> */}
    </>
  ),
  squaredLogo: (props) => (
    <>
      <Image
        sizes={
          props.size === "small"
            ? "(max-width: 456px) 136px, clamp(136px, 10vw, calc(136px * 1.66))"
            : props.size === "medium"
              ? "(max-width: 456px) 161px, clamp(161px, 10vw, calc(161px * 1.66))"
              : "(max-width: 456px) 250px, clamp(250px, 10vw, calc(250px * 1.66))"
        }
        loading="lazy"
        fetchpriority="low"
        image={props.plug.image}
        accessibilityLabel={props.plug.accessibilityLabel}
      />
    </>
  ),
  squaredMultiHero: (props) => (
    <>
      <Image
        sizes={
          props.size === "small"
            ? "(max-width: 456px) 136px, clamp(136px, 10vw, calc(136px * 1.66))"
            : props.size === "medium"
              ? "(max-width: 456px) 161px, clamp(161px, 10vw, calc(161px * 1.66))"
              : "(max-width: 456px) 250px, clamp(250px, 10vw, calc(250px * 1.66))"
        }
        loading="lazy"
        fetchpriority="low"
        image={props.plug.image}
        accessibilityLabel={props.plug.accessibilityLabel}
      />
    </>
  ),
  landscape: (props) => (
    <>
      <Image
        sizes="(max-width: 456px) 136px, clamp(136px, 10vw, 250px)"
        loading="lazy"
        fetchpriority="low"
        image={props.plug.image}
        accessibilityLabel={props.plug.accessibilityLabel}
      />
      <Title text={props.plug?.title} />
    </>
  ),
  landscapeLogo: (props) => (
    <>
      <Image
        sizes={
          props.size === "small"
            ? "(max-width: 456px) 228px, clamp(228px, 10vw, calc(228px * 1.66))"
            : "(max-width: 456px) 288px, clamp(288px, 10vw, calc(288px * 1.66))"
        }
        loading="lazy"
        fetchpriority="low"
        image={props.plug.image}
        accessibilityLabel={props.plug.accessibilityLabel}
      />
    </>
  ),
  editorial: (props) => (
    <>
      <Image
        sizes="(max-width: 456px) 339px, clamp(339px, 10vw, calc(339px * 1.66))"
        loading="lazy"
        fetchpriority="low"
        image={props.plug.image}
        accessibilityLabel={props.plug.accessibilityLabel}
      />
      {/* <Title text={props.plug?.title} /> */}
      {/* <Description text={props.plug?.description} /> */}
    </>
  ),
};
